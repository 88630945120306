import React from 'react';
import { Layout, Section, SectionContent, Stack, Button } from '../components';
import { Heading, Paragraph } from '@cheapreats/react-ui';
import { FaHome } from 'react-icons/fa';
import { Link } from 'gatsby';

const NotFoundErrorPage = () => (
    <Layout title="Page not found">
        <Section>
            <SectionContent>
                <Stack direction="column" spacing="container">
                    <Stack direction="column" spacing="element">
                        <Heading as="h1" size="heading" color="black">
                            <Paragraph as="span" size="h1" color="primary">
                                Error 404
                            </Paragraph>{' '}
                            | Page not found
                        </Heading>
                        <Paragraph>
                            Seems like your order has been dropped... No
                            worries, there's plenty of boba in the tea.
                        </Paragraph>
                    </Stack>
                    <div>
                        <Button as={Link} to="/" leftIcon={FaHome}>
                            Back to Home
                        </Button>
                    </div>
                </Stack>
            </SectionContent>
        </Section>
    </Layout>
);

export default NotFoundErrorPage;
